<template>
  <v-dialog persistent max-width="500" v-model="dialog" >
    <v-sheet class="pa-4" rounded="lg">
      <div class="d-flex align-center">
        <div class="poppins fw500">
          Contacts
        </div>
        <v-spacer/>
        <v-btn icon small @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <!-- <SearchBar class="my-2 grow-1" :outlined="true" :placeholder="'Search class'" :loading="loading1" :value.sync="search" @search="getContacts()"/> -->
      <v-row no-gutters class="my-2">
        <v-col cols="12">
          <section class="d-flex flex-row align-center pa-0">
            <v-text-field
                outlined
                dense
                flat
                class="custom-border general-custom-field poppins fw500"
                hide-details
                prepend-inner-icon="mdi-magnify"
                :placeholder="'Search'"
                :loading="loading"
                v-model="search"
            />
            <v-btn color="primary" class="poppins" @click="getContacts()"> Search</v-btn>
          </section>
        </v-col>
        <!-- <v-col cols="4" class="hidden-sm-and-down">
          <div class="d-flex align-center justify-end">
            <FormLabel class="mr-1" :label="'Filter by: '"/>
            <v-select 
              filled 
              class="col-7 noline general-custom-field roboto f14 secondary-1--text fw500" 
              dense
              hide-details
              :items="items"
              v-model="filter"
            />
          </div>
        </v-col> -->
      </v-row>
      <v-sheet height="300" class="overflow-y-auto">
        <a href="#" 
          class="text-decoration-none" 
          v-for="item in contacts" 
          :key="item.id"
          @click="$emit('startMessage', item)"
        >
          <v-sheet class="my-3 d-flex align-center" >
            <v-avatar class="" size="50">
              <v-img 
              :src="item.image?item.image.url:require('../../../assets/default-photo.png')"/>
            </v-avatar>
            <div class="poppins ml-3">
              <div class="f13 fw500">
                {{`${item.first_name} ${item.last_name}`}} 
              </div>
              <div class="f12 secondary--text">
                {{`${item.role}`}} 
              </div>
            </div>
          </v-sheet>
        </a>
      </v-sheet>
      <FormPagination 
        class="d-flex align-center justify-end"
          :pageCount="pageCount" 
          :page="page"
          :paginate="paginate"
          @page="(e) => {page = e, getContacts()}" 
          @paginate="(e) => {page = 1, paginate = e, getContacts()}"/>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: ['dialog'],
  data: () => ({
    items: ['ALL', 'USER', 'INSTRUCTOR'],
    filter: 'ALL',
    search: '',
    pageCount: 1,
    page: 1,
    paginate: '10',
    loading: false,
  }),

  watch: {
    dialog(val){
      this.search = ''

      if(val) {
        this.getContacts()
      }
    }
  },

  methods: {
    ...mapActions('instructor', ['getContactsAction']),

    getContacts(){
      this.loading = true
      this.getContactsAction({ search: this.search, page: this.page, paginate: Number(this.paginate) }).then((res) => {
        this.pageCount = res.last_page
        this.paginate = res.per_page.toString()
        this.page = res.current_page
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }

  },

  computed: {
    ...mapState('instructor', {
      contacts: (state) => state.contacts,
    })
    // contactList(){
    //   if (this.search == '') {
    //     if (this.filter == 'ALL' || this.$vuetify.breakpoint.mobile){
    //       return this.contacts
    //     } else {
    //       return this.contacts.filter(contact => contact.role == this.filter)
    //     }
    //   } else {
    //     if (this.filter == 'ALL' || this.$vuetify.breakpoint.mobile){
    //       return this.contacts.filter(contact => String(`${contact.first_name} ${contact.last_name}`).toLowerCase().search(this.search.toLowerCase()) !== -1)
    //     } else return this.contacts.filter(contact => contact.role == this.filter && String(`${contact.first_name} ${contact.last_name}`).toLowerCase().search(this.search.toLowerCase()) !== -1)
    //   }
      
    // }
  }
}
</script>