<template>
  <v-sheet color="#FAFAFA" rounded="" class="my-2">
    <div class="d-flex align-center pa-1 a">
      <div class="">
        <v-avatar>
          <v-img 
            v-if="user.id == item.sender.id"
            :src="item.receiver.image?item.receiver.image.url:require('@/assets/default-photo.png')"
            v-on:error="require('@/assets/default-photo.png')"
          />
          <v-img 
            v-else
            :src="item.sender.image?item.sender.image.url:require('@/assets/default-photo.png')"
            v-on:error="require('@/assets/default-photo.png')"
          />
        </v-avatar>
      </div>
      <div class="ml-2 flex-grow-1">
        <div class="d-flex align-center justify-space-between">
          <v-sheet 
            color="transparent" 
            class="overflow-x-hidden title-ellipse poppins f12 fw500" 
            max-width="100" 
            v-if="user.id == item.sender.id"
          >
            {{`${item.receiver.first_name} ${item.receiver.last_name}`}}
          </v-sheet>
          <v-sheet color="transparent" class="overflow-x-hidden title-ellipse poppins f12 fw500" max-width="100"  v-else>
            {{`${item.sender.first_name} ${item.sender.last_name}`}}
          </v-sheet>
          <div class=" poppins f10 secondary--text" 
          
          v-if="item.latest_private_message">
            {{$dateFormat.fromNow(item.latest_private_message.created_at)}}
          </div>
        </div>
        <v-sheet color="transparent" class="overflow-x-hidden title-ellipse poppins f10 secondary--text" max-width="200" v-if="item.latest_private_message">
          {{item.latest_private_message.message_body}}
        </v-sheet>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: ['item', 'user'],
  data: () => ({
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
  })
}
</script>

<style>

</style>