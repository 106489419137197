<template>
  <section v-if="!loading">
    <v-sheet class="custom-border border overflow-y-auto" max-height="77vh" min-height="77vh">
      <div class="d-flex flex-wrap" :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'align-stretch'">
        <!-- contacts -->
        <v-sheet v-if="viewContacts" :min-width="$vuetify.breakpoint.mobile ? '90vw' : !viewMessage ? '100%' : 350 " max-height="80vh" class="pa-3 overflow-y-auto">
          <SearchHeader/>
          <div class="d-flex align-center justify-space-between mt-2">
            <h4 class="poppins">
              Chats 
              <v-badge v-if="chat_count" class="ml-2" content="chat_count"/>
            </h4>
            <v-btn icon color="primary" @click="dialog=true">
              <v-icon>mdi-plus-box-outline</v-icon>
            </v-btn>
          </div>
          <v-sheet class="overflow-y-auto px-2" 
            max-height="27vh"
            min-height="min-content" 
            v-if="chat_lists.length>0">
            <a href="#"  
              class="text-decoration-none" 
              v-for="item in chat_lists" 
              :key="item.id"
              @click="viewMessage = true, $vuetify.breakpoint.xs ? viewContacts = false : viewContacts = true , showMessages(item)"
            >
              <ContactCard :item="item" :user="user" />
            </a>
          </v-sheet>
          <div class="f12 secondary--text text-center my-2" v-else>
            Start a conversation
          </div>
          <div class="d-flex align-center justify-space-between mt-2">
            <h4 class="poppins">Groups</h4>
            <v-btn icon color="primary" @click="dialog_group=true">
              <v-icon>mdi-plus-box-outline</v-icon>
            </v-btn>
          </div>
          <v-sheet class="overflow-y-auto px-2" 
            max-height="27vh"
            min-height="27vh"
          >
            <a 
              href="#" 
              class="text-decoration-none" 
              v-for="item in group_lists" 
              :key="item.id"
              @click="viewMessage = true, $vuetify.breakpoint.xs ? viewContacts = false : viewContacts = true , showGroupMessages(item)"
            >
              <GroupCard :item="item" :user="user"/>
            </a>
          </v-sheet>
        </v-sheet>
        <!--  -->
        <div class="" v-if="!$vuetify.breakpoint.mobile">
          <v-divider vertical/>
        </div>

        <!-- chat -->
        <v-sheet class="d-flex flex-column flex-grow-1" 
          max-height="77vh"
          min-height="77vh"
          v-if="viewMessage"
        >
          <v-sheet class="d-flex align-center" v-if="friend" :class="!$vuetify.breakpoint.mobile && 'ma-3'">
            <v-btn icon v-if="$vuetify.breakpoint.mobile" @click="viewMessage = false, viewContacts = true" color="primary">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-avatar class="" size="50">
              <v-img 
                :src="friend.image ? friend.image.url: require('../../assets/default-photo.png')"
                v-on:error="friend.image = null"
              />
            </v-avatar>
            <div class="mx-2 mt-4">
              <h4 class="poppins fw500">{{`${friend.first_name} ${friend.last_name} ${friend.suffix ? friend.suffix : '' }`}}</h4>
              <p class="poppins secondary--text f12">{{friend.role}}</p>
            </div>
          </v-sheet>
          <v-sheet class="d-flex align-center justify-space-between ma-3" v-else-if="group">
            <v-btn icon v-if="$vuetify.breakpoint.mobile" @click="viewMessage = false, viewContacts = true" color="primary">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <div class="d-flex align-start">
              <v-avatar class="" size="50">
                <v-img :src="group.image?group.image.url:require('../../assets/default-photo-group.png')"
                  v-on:error="group.image = null"
                />
              </v-avatar>
              <div class="mx-2 mt-4">
                <h4 class="poppins fw500">{{`${group.name}`}}</h4>
              </div>
            </div>
            <v-spacer/>
            <v-btn @click="group_dialog=true" icon color="primary">
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                >
                  <v-icon >
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
                <v-list>
                  <v-list-item class="roboto f14 d-flex align-center" @click="removeGroupImage" v-if="group.image">
                    <v-icon size="18" class="mr-2">
                      mdi-image-remove-outline
                    </v-icon>
                    Remove Group Photo
                  </v-list-item>
                  <v-list-item class="roboto f14 d-flex align-center" @click="group_photo = true">
                    <v-icon size="18" class="mr-2">
                      mdi-image-edit-outline
                    </v-icon>
                    Change Group Photo
                  </v-list-item>
                  <v-list-item class="roboto f14 d-flex align-center" @click="group_member_dialog = true">
                    <v-icon size="18" class="mr-2">
                      mdi-account-multiple-outline
                    </v-icon>
                    See members
                  </v-list-item>
                </v-list>
            </v-menu>
          </v-sheet>
          <v-divider/>

          <!-- chat messages -->
          <v-sheet class="overflow-y-auto ma-2 pa-2" 
            id="chat-wrapper" 
            max-height="60vh"
          >
            <v-sheet color="transparent" 
              v-if="loadingMessages" 
              class="d-flex flex-column justify-center align-center" 
              height="55vh"
            >
              <circular/>
            </v-sheet>
            <v-sheet color="transparent" 
              v-if="friend && !loadingMessages" 
              class="d-flex flex-column justify-end" 
              min-height="55vh"
            >
              <div v-if="message.from_user_id === user.id || message.to_user_id === user.id" v-for="message in messages.private_messages" :key="message.id" 
                class="mx-3 my-2 d-flex flex-column justify-end"
                :class="user.id == message.from_user_id?'align-end':'align-start'">
                  <ChatCard 
                    :message="message"
                    :data="messages" 
                    :user="user" 
                    type="private"
                  />
              </div>
              
            </v-sheet>
            <v-sheet 
              color="transparent" 
              v-if="group && !loadingMessages" 
              class="d-flex flex-column justify-end" 
              min-height="55vh"
            >
              <!-- <div v-for="item in messages" :key="item.id" class="text-center secondary--text f12">
                {{`${item.first_name} ${item.last_name} has been added to the group`}}
              </div> -->
              <div v-for="message in messages.group_messages" :key="message.id" 
                class="mx-3 my-2 d-flex flex-column justify-end"
                :class="message.synced_users ? 'align-center' : user.id == message.sender_id?'align-end':'align-start'">
                <ChatCard :message="message" :user="user" :friend="message.sender" type="group"/>
              </div>
            </v-sheet>
          </v-sheet>
          <ChatInput v-if="friend || group" :friend="friend" :group="group" :user="user" :private_chat="private_chat" class="pb-3" @successSend="successSend"/>
        </v-sheet>
      </div>
      
    </v-sheet>
    <ContactDialog 
      :dialog="dialog"
      @close="dialog=false"
      @startMessage="startMessage"
    />

    <GroupContactDialog 
      v-if="group"
      :dialog="group_dialog" 
      :group_chat_uuid="group.uuid"
      @close="group_dialog=false"
    />

    <GroupDialog 
      :dialog="dialog_group"
      @close="dialog_group=false"
    />

    <GroupMembersDialog 
      v-if="group"
      :dialog="group_member_dialog" 
      :members="group.group_chat_users"
      @close="group_member_dialog = false"/>

    <PhotoUpload v-if="group" :id="group.uuid" :modal="group_photo" @close="group_photo = false" :type="'group'" :role="'instructor'"/>

  </section>
  <circular v-else />
</template>

<script>
import SearchHeader from '../../components/teacher/inbox/SearchHeader.vue'
import ContactCard from '../../components/teacher/inbox/ContactCard.vue'
import Chat from '../../components/teacher/inbox/Chat.vue'
import ChatInput from '../../components/teacher/inbox/ChatInput.vue'
import ContactDialog from '../../components/teacher/inbox/ContactDialog.vue'
import GroupContactDialog from '../../components/teacher/inbox/GroupContactDialog.vue'
import GroupDialog from '../../components/teacher/inbox/GroupDialog.vue'
import GroupCard from '../../components/teacher/inbox/GroupCard.vue'
import ChatCard from '../../components/teacher/inbox/ChatCard.vue'
import GroupMembersDialog from '../../components/user/inbox/GroupMembersDialog.vue'
import PhotoUpload from '../../components/main/PhotoUpload.vue'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    SearchHeader,
    ContactCard,
    Chat,
    ChatInput,
    ContactDialog,
    GroupDialog,
    GroupCard,
    ChatCard,
    GroupContactDialog,
    GroupMembersDialog,
    PhotoUpload
  },
  data: () => ({
    dialog: false,
    group_dialog: false,
    dialog_group: false,
    group_member_dialog: false,
    group_photo: false,
    friend: null,
    group: null,    
    private_chat: null,
    chat_wrapper: null,
    view: false,
    chat_count: 0,
    loading: false,
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    viewContacts: true,
    viewMessage: false,
    loadingMessages: false
  }),
  computed: {
    ...mapState('instructor', {
      chat_lists: (state) => state.chat_lists.sort(function(a, b) {
        if(a.latest_private_message !== null && b.latest_private_message !== null) {
          return new Date(b.latest_private_message.updated_at) - new Date(a.latest_private_message.updated_at)
        } else if (a.latest_private_message !== null) {
          return new Date(b.created_at) - new Date(a.latest_private_message.updated_at)
        } else if (b.latest_private_message !== null) {
          return new Date(b.latest_private_message.updated_at) - new Date(a.created_at)
        } else {
          return (new Date(b.latest_private_message) - new Date(a.created_at))
        }
      }),
      group_lists: (state) => state.group_lists.sort(function(a, b) {
        if(a.latest_group_message !== null && b.latest_group_message !== null) {
          return new Date(b.latest_group_message.updated_at) - new Date(a.latest_group_message.updated_at)
        } else if (a.latest_group_message !== null) {
          return new Date(b.created_at) - new Date(a.latest_group_message.updated_at)
        } else if (b.latest_group_message !== null) {
          return new Date(b.latest_group_message.updated_at) - new Date(a.created_at)
        } else {
          return (new Date(b.created_at) - new Date(a.created_at))
        }
      }),
      messages: (state) => state.messages,
    }),
    ...mapState({
      user: (state) => state.user,
    })
  },  
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
    this.getChatListsAction()
    this.getGroupListsAction()
    this.messages = []
    if(this.$route.params.private_chat_uuid) {
      this.group = null
      this.showMessagesAction(this.$route.params.private_chat_uuid)
      .then((res) => {
        this.loadingMessages = false
        this.dialog=false
        this.viewMessage = true
        this.viewContacts = true
        this.onResize()
        if(this.user.id === this.messages.creator_id) {
          this.friend = this.messages.user
        }
        if(this.user.id === this.messages.user_id) {
          this.friend = this.messages.creator
        }

        // if(this.user == )
        // if(this.user.id == e.sender.id) this.friend = e.receiver
        // if(this.user.id == e.receiver.id) this.friend = e.sender

        this.private_chat = this.messages
        
        this.scroll()
        // this.$route.params.private_chat_uuid =  null
        // this.$route.params.group_chat_uuid = null
      })
    }

    if(this.$route.params.group_chat_uuid) {
      this.private_chat = null
      this.showGroupChatAction(this.$route.params.group_chat_uuid).then(()=>{
        this.viewMessage = true
        this.viewContacts = true
        this.onResize()
        // this.scroll()
        // this.$route.query.group_chat_uuid = null
        // this.$route.query.private_chat_uuid =  null
        this.group = this.messages
      })
    }
  },   
  methods: {
    ...mapActions('instructor', [
      'getChatListsAction',
      'showMessagesAction', 
      'sendMessageAction',
      'getGroupListsAction',
      'sendGroupChatAction',
      'showGroupChatAction',
      'removeGroupPhotoAction'
    ]),

    ...mapMutations(['alertMutation']),

    onResize () {
      if(!this.$vuetify.breakpoint.smAndDown || !this.$vuetify.breakpoint.mobile) {
        this.viewContacts = true
      }
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile) {
        if(this.viewMessage) this.viewContacts = false
      }
      
    },

    showMessages(e) {
      this.private_chat = e
      this.group = null
      this.loadingMessages = true
      this.showMessagesAction(e.uuid)
      .then(() => {
        this.dialog=false
        this.loadingMessages = false
        if(this.user.id == e.sender.id) this.friend = e.receiver
        if(this.user.id == e.receiver.id) this.friend = e.sender

        this.scroll()
      })
    },

    startMessage(e) {
      this.$store.commit('instructor/messagesMutation',[])
      this.private_chat=null
      this.friend = e
      
      this.viewMessage = true
      this.onResize()

      if(e.private_chat_as_creator) {
        this.private_chat = e.private_chat_as_creator
      }
      
      if(e.private_chat_as_user) {
        this.private_chat = e.private_chat_as_user
      }

      if(this.private_chat) {
        this.showMessagesAction(this.private_chat.uuid)
        // .then(() => {
        //   this.scroll()
        // })
      }
      this.dialog = false
      this.$router.replace({ query: { private_chat_uuid: this.private_chat.uuid, timestamp: Date.now() } });
      this.scroll()
    },

    send(e) {
      if (this.friend) {
        return this.private(e)
      }

      if (this.group) {
        return this.group_chat(e)
      }
    },

    successSend(){
      if (this.friend) {
        this.getChatListsAction().then(() => {
            this.group = null
            if(this.user.id === this.messages.creator_id) {
              this.friend = this.messages.user
            }
            if(this.user.id === this.messages.user_id) {
              this.friend = this.messages.creator
            }

            this.private_chat = this.messages
            this.scroll()
        })
      }

      if (this.group) {
        this.private = null
        this.showGroupChatAction(this.group.uuid).then(() => {
          this.getGroupListsAction().then(()=>{
            this.scroll()
          })
        })
      }
    },

    private(e) {
      let form = new FormData()

      form.append('private_chat_id', this.private_chat ? this.private_chat.id : '')
      form.append('message_body', message)
      form.append('to_user_id', this.friend.id)
      form.append('from_user_id', this.user.id)
      form.append("_method", 'POST');

      attachments.forEach((item, i) => {
        form.append(`attachments[${i}]`, item)
      });

      this.sendMessageAction(form).then(() => {
        this.getChatListsAction()
          this.group = null
          if(this.user.id === this.messages.creator_id) {
            this.friend = this.messages.user
          }
          if(this.user.id === this.messages.user_id) {
            this.friend = this.messages.creator
          }

          this.private_chat = this.messages
          this.scroll()
      })
    },

    group_chat(e) {
      this.sendGroupChatAction({
        uuid: this.group.uuid,
        group_chat_id: this.group.id,
        synced_users: false,
        message_body: e.message,
        sender_id: this.user.id
      }).then(() => {
        this.getGroupListsAction()
        this.scroll()
      })
    },

    removeGroupImage() {
      this.removeGroupPhotoAction(({ id: this.group.uuid })).then(() => {
        this.alertMutation({
          show: true,
          text: 'Group chat photo removed successfully.',
          type: "success"
        })
      }).catch(() => {
        this.alertMutation({
          show: true,
          text: 'Something went wrong.',
          type: "error"
        })
      })
    },

    showGroupMessages(item) {
      this.friend= null
      this.group = item
      this.$store.commit('instructor/messagesMutation', [])
      this.showGroupChatAction(this.group.uuid).then(()=>{
        this.loadingMessages = false
        this.viewMessage = true
        this.viewContacts = true
        this.onResize()
        this.$router.replace({ query: { group_chat_uuid: this.group.uuid, timestamp: Date.now() } });
        this.scroll()
      })
    },

    scroll() {
      setTimeout(() => {
        let chat_wrapper = document.getElementById("chat-wrapper");
        chat_wrapper.scrollTop = chat_wrapper.scrollHeight;
      },100);
    },
  },

  watch: {
    group_lists(new_group, prev_group){
      if( new_group.length !== prev_group.length ) {
        this.showGroupMessages(new_group[0])
      }
      if(this.group !== null) {
        this.group = new_group.filter(_group => _group.id === this.group.id)[0]
        this.scroll()
      }
    },
  }
}
</script>
